import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import './index.scss';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../ModalComponent';
import ProductDetailRadioButton from '../../components/ProductDetailRadioButton';
import { useCart } from '../../contexts/CartContent';
import { Item, OptionGroup, Gallery, Store } from '../../types/dataType';
import { useStore } from '../../contexts/StoreContent';
import Button from '../../components/Button';
import CartQuantityButton from '../../components/CartQuantityButton';
import closeWhite from '../../assets/icons/closeWhite.svg';
import galleryIcon from '../../assets/icons/gallery.svg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

interface ProductDetailEditModalProps {
  modal: boolean;
  setModal: (value: boolean) => void;
  data?: Item;
  cartId?: number;
  closeAllModal?: () => void;
}


// Functional component with props type
const ProductDetailEditModal: React.FC<ProductDetailEditModalProps> = ({
  modal,
  setModal,
  data,
  cartId,
  closeAllModal
}) => {
  const [dataItems, setDataItems] = useState<Item>();
  const [settingData, setSettingData] = useState<OptionGroup[]>([]);
  const [quantity, setQuantity] = useState(1);
  const { t } = useTranslation();
  const { storeData, isQRExpired } = useStore();
  const { cart, EditToCart } = useCart();
  const [errors, setErrors] = useState({});
  const [galleryImage, setGalleryImage] = useState<Gallery[]>([]);
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>("");
  const [imageError, setImageError] = useState(false);
  const [image, setImage] = useState('');


  useEffect(() => {
    isQRExpired();
    if (data) {
      const selectedItem = cart.find(cartItem => cartItem.id === Number(cartId));
      if (selectedItem) {
        const galleryImages = data?.images?.map((image) => ({
          original: image?.url ?? '',
          thumbnail: image?.url ?? '',
        }));
        if (galleryImages) {
          setGalleryImage(galleryImages);
        }
        setSettingData(selectedItem?.addOn || []);
        setQuantity(selectedItem?.quantity)
        setRemark(selectedItem?.remark ?? '')
      } else {
        setSettingData(data?.option_group_list || []);
      }
      setDataItems(data);
    }
  }, [data, cartId, cart, modal]);


  const handleOptional = (groupIndex: number, index: number) => {

    const updatedOptionGroups = [...settingData];

    if (updatedOptionGroups[groupIndex] && updatedOptionGroups[groupIndex].options) {
      const updatedOptions = [...updatedOptionGroups[groupIndex].options];
      const min = parseInt(updatedOptionGroups[groupIndex]?.min_select ?? '0', 10);
      const max = parseInt(updatedOptionGroups[groupIndex]?.max_select ?? '0', 10);


      if (updatedOptions[index].qty === 1) {
        updatedOptions[index].qty = 0;
      } else {
        if (min <= 1 && max === 1) {
          _.forEach(updatedOptions, (item) => {
            item.qty = 0;
          });
          updatedOptions[index].qty = 1;
        } else {
          let limit = 0;
          _.forEach(updatedOptions, (op) => {
            if (op?.qty) {
              limit += op?.qty;
            }
          });
          if (min <= limit && max > limit) {
            updatedOptions[index].qty = 1;
          }
        }
      }


      updatedOptionGroups[groupIndex] = {
        ...updatedOptionGroups[groupIndex],
        options: updatedOptions,

      };
      setSettingData(updatedOptionGroups);
    }
  };


  useEffect(() => {
    if (settingData) {
      const inputData = _.cloneDeep(errors);
      _.forEach(settingData, (item, index) => {
        const min = parseInt(item?.min_select ?? '0', 10);
        const max = parseInt(item?.max_select ?? '0', 10);
        let limit = 0;
        _.forEach(item?.options, (op) => {
          if (op?.qty) {
            limit += op?.qty;
          }
        });
        if (min <= limit && max >= limit) {
          _.unset(inputData, index);
        } else {
          _.set(inputData, index, 'error');
        }
      });
      // console.log('error', inputData)
      setErrors(inputData);
    }
  }, [settingData]);


  const handleQtyChange = (qty: number, groupIndex: number, index: number) => {

    const updatedOptionGroups = [...settingData];

    if (updatedOptionGroups[groupIndex] && updatedOptionGroups[groupIndex].options) {
      const updatedOptions = [...updatedOptionGroups[groupIndex].options];

      updatedOptions[index].qty = qty;
      // updatedOptions[index].totalAmount = qty * (updatedOptions[index].price ?? 0);

      // const groupTotalAmount = updatedOptions.reduce((total, option) => {
      //     return total + (option.totalAmount ?? 0);
      // }, 0);

      updatedOptionGroups[groupIndex] = {
        ...updatedOptionGroups[groupIndex],
        options: updatedOptions,
        // totalAmount: groupTotalAmount,
      };
      // console.log('handleQtyChange', updatedOptionGroups)
      setSettingData(updatedOptionGroups);

    }
  };


  const increaseQty = () => {
    const newQty = quantity + 1;
    setQuantity(newQty);
  };

  const decreaseQty = () => {
    if (quantity >= 0) {
      const newQty = quantity - 1;
      setQuantity(newQty);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemark(e.target.value);
  };


  const updateCart = () => {
    if (dataItems) {
      // console.log('settingData', settingData)
      EditToCart(Number(cartId), storeData, settingData, quantity, remark);
      if (closeAllModal) {
        closeAllModal();
      }
      closeModal();
    }
  }

  const optionSettingDisplay = (option: OptionGroup, groupIndex: number) => {
    // console.log(option);
    const min = parseInt(option?.min_select ?? '0', 10);
    const max = parseInt(option?.max_select ?? '0', 10);
    if (max === 1) {
      return (
        <div className='editOrderItem-setting-inner'>
          <div className='editOrderItem-setting-inner-label'>
            {t(option?.group_name ?? '')}
          </div>
          <div className='editOrderItem-setting-inner-description'>
            {min === 0 ? t('Optional') : t('Pick 1')}
          </div>
          <div className='editOrderItem-setting-inner-option'>
            {_.map(option?.options, (item, index) => (
              <div className='editOrderItem-setting-inner-option-box'
                key={index}>
                <ProductDetailRadioButton item={item}
                  active={item?.qty === 1}
                  setSelectedAction={() => handleOptional(groupIndex, index)} />
              </div>
            ))}
          </div>
        </div>
      )
    } else if (max > 1) {
      return (
        <div className='editOrderItem-setting-inner top'>
          <div className='editOrderItem-setting-inner-label'>
            {t(option?.group_name ?? '')}
          </div>
          <div className='editOrderItem-setting-inner-description'>
            {min === 0 ? t('Optional') : t('Pick 1')}
          </div>
          <div className='editOrderItem-setting-inner-option'>
            {_.map(option?.options, (item, index) => (
              <div className='editOrderItem-setting-inner-option-box'
                key={index}>
                {/* <ProductDetailQuantityButton
                  item={item}
                  quantity={item.qty ?? 0}
                  setAction={(qty) => handleQtyChange(qty, groupIndex, index)} /> */}
                <ProductDetailRadioButton item={item}
                  active={item?.qty === 1}
                  setSelectedAction={() => handleOptional(groupIndex, index)} />
              </div>
            ))}
          </div>
        </div>
      )
    }
  }

  const renderGallery = (item: { original: string }) => (
    <div className='imageGallery-img'>
      <img
        src={item.original}
        alt="Bereach"
      />
    </div>
  );


  const closeModal = () => {
    setQuantity(1)
    setRemark('')
    setSettingData([])
    setDataItems({})
    setErrors({})
    setModal(false)
  }

  const displayImage = (item: string) => {
    if (!imageError && item) {
      return (
        <img
          src={item}
          alt="Bereach Image"
          onError={() => setImageError(true)}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (dataItems?.main_image) {
      setImageError(false)
      setImage(dataItems?.main_image);
    } else {
      setImageError(true)
      // src = require('../../assets/images/logoText.png');
    }
  }, [dataItems]);


  return (
    <>
      <ModalComponent modal={modal} setModal={setModal} fullHeight>
        <div className='editOrderItem'>
          <div className='editOrderItem-header'>
            <div className='editOrderItem-header-inner'>
              <div className='editOrderItem-header-inner-icon' onClick={() => closeModal()}>
                <img src={closeWhite} />
              </div>
            </div>
          </div>
          {!imageError ? (
            <div className='editOrderItem-img' >
              {displayImage(image)}
              {!_.isEmpty(galleryImage) && (
                <div className='editOrderItem-img-icon' onClick={() => setOpenGallery(true)}>
                  <img src={galleryIcon} />
                  {galleryImage?.length}
                </div>
              )}
            </div>
          ) : (
            <div className='editOrderItem-errorImg'>
            </div>
          )}
          <div className='editOrderItem-detail'>
            <div className='editOrderItem-detail-inner'>
              <div className='editOrderItem-detail-inner-title'>
                {t(dataItems?.name ?? '')}
              </div>
              <div className='editOrderItem-detail-inner-price'>
                {dataItems?.price && `RM ${dataItems?.price?.toFixed(2)}`}
              </div>
              <div className='editOrderItem-detail-inner-description'>
                {dataItems?.category_list && dataItems.category_list.length > 0
                  && dataItems.category_list
                    .map(cat => cat.display_name ? t(`${cat.display_name}`) : '')
                    .filter(Boolean)
                    .join(' / ')
                }
              </div>
            </div>
          </div>
          <div className='editOrderItem-setting'>
            {_.map(settingData, (option, index) => (
              <div key={index}>
                {optionSettingDisplay(option, index)}
              </div>
            ))}
            <div className='editOrderItem-setting-inner noMarginBottom'>
              <div className='editOrderItem-setting-inner-label'>
                {t('Special instruction')}
              </div>
              <textarea
                value={remark}
                onChange={handleChange}
                placeholder={t('E.g. No onions ,please')}
                rows={4}
                style={{ width: '100%' }}
                className='editOrderItem-setting-inner-textArea'
              />
            </div>
          </div>
          <div className='editOrderItem-addCartContainer'>
            <div className='editOrderItem-addCartContainer-qtySetting'>
              <CartQuantityButton quantity={quantity} increaseQuantity={increaseQty}
                decreaseQuantity={decreaseQty} />
            </div>
            <div className='editOrderItem-addCartContainer-btn'>
              <Button text={`${quantity > 0 ? "Update Cart" : "Back To Menu"}`} backgroundColor={!_.isEmpty(errors) ? '#e7e7e7' : '#190DA2'} action={() => {
                if (_.isEmpty(errors)) {
                  updateCart()
                }
              }} />
            </div>
          </div>
        </div>
      </ModalComponent>
      {!_.isEmpty(galleryImage) && (
        <ModalComponent modal={openGallery} setModal={setOpenGallery} >
          <div className='imageGallery'>
            <ImageGallery items={galleryImage} renderItem={renderGallery} showFullscreenButton={false} showPlayButton={false} />
          </div>
        </ModalComponent>
      )}
    </>
  );
};

export default ProductDetailEditModal;
