import React, { useState, useEffect } from 'react';

// Define the props interface
interface ImageDisplayProps {
  mainImage?: string;
}

// Functional component with props type
const ImageDisplay: React.FC<ImageDisplayProps> = ({
  mainImage
}) => {
  const [error, setError] = useState(false);
  const [image, setImage] = useState('');



  useEffect(() => {
    console.log(mainImage)
    if (mainImage) {
      setError(false)
      setImage(mainImage);
    } else {
      setError(true)
      // src = require('../../assets/images/logoText.png');
    }
  }, [mainImage]);


  if (!error && image) {
    return (
      <img
        src={image}
        alt="Bereach Image"
        onError={() => {
          console.log('error')
          setError(true)
        }}
      />
    );
  } else {
    return null;
  }


};

export default ImageDisplay;